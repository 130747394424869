import React from "react";
import { Router, Route } from "react-router";
import { createBrowserHistory } from "history";
import HomeComponent from "./components/containers/HomeComponent";
import SetIndustryName from "./components/containers/SetIndustryName";
import LogoInspirationContainer from "./components/containers/LogoInspirationContainer";
import ColorPaletteList from "./components/containers/ColorPaletteList";
import LogoList from "./components/containers/LogoList";
import DownloadContainer from "./components/containers/DownloadContainer";
import TaglineComponent from "./components/TaglineComponent";

const history = createBrowserHistory();

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

const AppRoutes = () => (
  <Router history={history}>
    <div>
      <Route path="*" component={ScrollToTop} />
      <Route exact path="/" component={HomeComponent} />
      <Route path="/tagline" component={TaglineComponent} />
      <Route path="/industry" component={SetIndustryName} />
      <Route path="/inspiration" component={LogoInspirationContainer} />
      <Route path="/color-palette" component={ColorPaletteList} />
      <Route path="/logos" component={LogoList} />
      {/* <Route path="/download" component={DownloadContainer} /> */}
      <Route path="/logo-mockup" component={DownloadContainer} />
    </div>
  </Router>
);

export default AppRoutes;
