import React from "react";
import CompanyName from "../company-name/CompanyName";
import Navbar from "../../components/common/Navbar";

const HomeComponent = () => {
  return (
    <>
      <Navbar />
      <div className="logo-generator-base">
        <div className="container">
          <CompanyName />
        </div>
      </div>
    </>
  );
};

export default HomeComponent;
