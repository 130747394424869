import React from "react";
import { v4 as uuid } from "uuid";
import Navbar from "../../components/common/Navbar";
import HeaderComponent from "../../components/common/HeaderComponent";
import ColorPaletteComponent from "../../components/ColorPaletteComponent";
import ProgressBarComponent from "../../components/common/ProgressBarComponent";
import LoadingComponent from "../../components/common/LoadingComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectColorPalette } from "../../lib/redux/actions";
import generateLogos from "../../utils/generateLogos";
import { GENERATED_LOGOS } from "../../lib/redux/actionTypes";

const Palettes = withRouter(
  ({ palettes, onClick, isFetching, state, onGenerate, history }) => {
    let paletteArray = [];

    palettes.forEach((palette) => {
      paletteArray.push(
        <div key={uuid()} className="col-md-3 col-sm-4 col-xs-6">
          <ColorPaletteComponent palette={palette} onClick={onClick} />
        </div>
      );
    });

    return (
      <>
        <LoadingComponent isFetching={isFetching} />
        <Navbar />
        <div className="logo-generator-base">
          <div
            className="container"
            style={{
              maxWidth: "920px",
              paddingBottom: "30px",
            }}
          >
            <div className="color-palette-container">
              <HeaderComponent headerText="Choose up to 2 color styles you like." />
              <div className="row">{paletteArray}</div>
            </div>
          </div>
        </div>
        <ProgressBarComponent
          history={history}
          isGeneratingLogos={true}
          isColorSelection={true}
          palettes={palettes}
          state={state}
          onGenerate={onGenerate}
        />
      </>
    );
  }
);

const mapStateToProps = (state) => ({
  state: state,
  isFetching: state.isFetching,
  palettes: state.palettes,
});

const mapDispatchToProps = (dispatch) => ({
  onClick: (name) => {
    dispatch(selectColorPalette(name));
  },
  onGenerate: (state) => {
    // dispatch(fetchLogos(state));
    const { inspirations, companyName, tagline, palettes } = state;
    const logos = generateLogos(inspirations, companyName, tagline, palettes);
    dispatch({
      type: GENERATED_LOGOS,
      logos,
    });
  },
});

const ColorPaletteList = connect(mapStateToProps, mapDispatchToProps)(Palettes);

export default ColorPaletteList;
