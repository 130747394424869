import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { LogoMockup } from "../logo-mockup";

const Download = withRouter(({ history, pickedLogo, downloadSelectedLogo }) => {
  return (
    <div>
      {/* <DownloadComponent history={history} logo={logo} onDownload={downloadSelectedLogo} /> */}
      <LogoMockup
        history={history}
        pickedLogo={pickedLogo}
        onDownload={downloadSelectedLogo}
      />
    </div>
  );
});

const mapStateToProps = (state) => ({
  pickedLogo: state.selectedLogo,
});

const DownloadContainer = connect(mapStateToProps)(Download);

export default DownloadContainer;
