import fetch from 'isomorphic-fetch';
import { SET_COMPANY_NAME, SET_LOGO_CONCEPTS } from './actionTypes';
import { SET_TAGLINE_TEXT } from './actionTypes';
import { SET_INDUSTRY_NAME } from './actionTypes';
import { SET_COMPANY_DESCRIPTION } from './actionTypes';
import { SELECT_LOGO_INSPIRATION } from './actionTypes';
import { SELECT_COLOR_PALETTE } from './actionTypes';
import { REQUEST_ICONS } from './actionTypes';
import { RECEIVE_ICONS } from './actionTypes';
import { REQUEST_LOGOS } from './actionTypes';
import { RECEIVE_LOGOS } from './actionTypes';
import { REQUEST_MORE_LOGOS } from './actionTypes';
import { RECEIVED_MORE_LOGOS } from './actionTypes';
import { SELECT_LOGO } from './actionTypes';
import { MAKE_REQUEST } from './actionTypes';
import { REQUEST_SUCCESS } from './actionTypes';

import config from '../../config/config.json'

export const setCompanyName = (name) => {
  return { type: SET_COMPANY_NAME, name }
};

export const setTaglineText = (tagline) => {
  return { type: SET_TAGLINE_TEXT, tagline }
};

export const setIndustryName = (industry) => {
  return { type: SET_INDUSTRY_NAME, industry }
};

export const setCompanyDescription = (description) => {
  return { type: SET_COMPANY_DESCRIPTION, description }
};

export const selectLogoInspiration = (inspiration) => {
  return { type: SELECT_LOGO_INSPIRATION, inspiration }
};

export const selectColorPalette = (name) => {
  return { type: SELECT_COLOR_PALETTE, name }
};

export const requestIconsByTerm = (term) => {
  return { type: REQUEST_ICONS, term }
};

export const receiveIcons = (icons) => {
  return { type: RECEIVE_ICONS, icons }
};

export const requestLogos = (chars) => {
  return { type: REQUEST_LOGOS, chars }
};

export const receiveLogos = (concepts) => {
  return { type: RECEIVE_LOGOS, concepts }
};

export const requestMoreLogos = (chars) => {
  return { type: REQUEST_MORE_LOGOS, chars }
};

export const receivedMoreLogos = (concepts) => {
  return { type: RECEIVED_MORE_LOGOS, concepts }
};

export const selectLogo = (logo) => {
  return { type: SELECT_LOGO, logo }
};

export const makeRequest = () => {
  return { type: MAKE_REQUEST }
};

export const requestSuccess = () => {
  return { type: REQUEST_SUCCESS }
};

export function fetchIcons(term) {
  return dispatch => {

    dispatch(requestIconsByTerm(term));

    return fetch(`https://fathomless-harbor-10088.herokuapp.com/api/icons/${term}`)
      .then(response => response.json())
  }
}

/**
 * @deprecated This function is deprecated and will be removed in future versions.
 */
export function fetchLogos(chars) {
  return async dispatch => {
    dispatch(requestLogos(chars));

    const URL = config.URLS.local + '/api/logos/chars';

    const generateLogoRequest = {
      method: 'POST',
      body: JSON.stringify(chars),
      headers: { 'Content-Type': 'application/json' }
    };

    try {
      const response = await fetch(URL, generateLogoRequest);
      const res = await response.json();

      if (response.status === 200) {
        dispatch(receiveLogos(res.concepts));
        return Promise.resolve(res);
      } else {
        return Promise.reject(res);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

// Action creator for logo concepts setting to reducer
export const setLogoConceptsActionCr = (concepts) => {
  return ({
    type: SET_LOGO_CONCEPTS,
    payload: concepts
  })
};
