import React from 'react';
import logo from '../../assets/svgs/logo.svg';

class Navbar extends React.Component {

    render() {
        return (
            <nav className="logo-generator-navbar">
                <div className="container">
                    <a href="/">
                        <img src={logo} className="logo" alt="Logogen" />
                    </a>
                    <div className="right">
                        <a href="/">
                            <button className="start-over">Start Over</button>
                        </a>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Navbar;