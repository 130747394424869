import { parse } from "node-html-parser";
import DOMPurify from "dompurify";

export function updateSvgAttributes(
  svg,
  outerSvgAttrs = {},
  innerSvgAttrs = {}
) {
  // Parse the SVG string into an XML document
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(svg, "image/svg+xml");

  const outerSvgEl = xmlDoc.documentElement;
  const innerSvgEl = xmlDoc.querySelector("svg > svg");

  // Set the provided outer attributes programmatically
  if (Object.keys(outerSvgAttrs).length > 0 && outerSvgEl) {
    outerSvgEl.setAttribute("preserveAspectRatio", "xMinYMin meet");
    for (let [key, value] of Object.entries(outerSvgAttrs)) {
      outerSvgEl.setAttribute(key, value);
    }
  }

  // Set the provided inner attributes programmatically
  if (Object.keys(innerSvgAttrs).length > 0 && innerSvgEl) {
    innerSvgEl.setAttribute("preserveAspectRatio", "xMinYMin meet");
    for (let [key, value] of Object.entries(innerSvgAttrs)) {
      innerSvgEl.setAttribute(key, value);
    }
  }
  // Remove the <defs> elements
  const defsElements = xmlDoc.querySelectorAll("defs");
  if (defsElements.length > 0) {
    defsElements.forEach((def) => def.parentNode.removeChild(def));
  }

  // Serialize the XML document back to a string
  const serializer = new XMLSerializer();
  let updatedIconSvg = serializer.serializeToString(xmlDoc);

  // Sanitize the updated SVG string using DOMPurify
  updatedIconSvg = DOMPurify.sanitize(updatedIconSvg);

  return updatedIconSvg;
}

export const getLogoWithTextBasedIcon = (
  textualSVGIcon = "",
  businessName = "",
  sloganName = "",
  businessFillColor = "#a80025",
  sloganFillColor = "#f55",
  businessFontFamily = "Montserrat",
  sloganFontFamily = "Montserrat",
  outerSvgAttrs = {},
  innerSvgAttrs = {}
) => {
  // Width of the icon is 100 for positioning the text
  const iconWidth = 100;
  // Spacing between icon and text
  const textSpacing = 10;
  // Starting position for the text with spacing
  const textXPosition = iconWidth + textSpacing;

  let svgLogoTemplate = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${outerSvgAttrs.width}" height="100">
    ${textualSVGIcon}
    <text fill="${businessFillColor}" font-size="58" x="${textXPosition}" y="45" text-anchor="left" font-family="${businessFontFamily}">${businessName}</text>
    <text fill="${sloganFillColor}" font-size="25" x="${textXPosition}" y="80" text-anchor="left" font-family="${sloganFontFamily}">${sloganName}</text>
  </svg>`;

  svgLogoTemplate = updateSvgAttributes(
    svgLogoTemplate,
    outerSvgAttrs,
    innerSvgAttrs
  );
  return svgLogoTemplate;
};

export const measureTextWidth = (text, fontSize, fontFamily) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = `${fontSize}px ${fontFamily}, sans-serif`;
  return context.measureText(text).width;
};

export const parseSvg = (svgString) => {
  const businessTextFontSize = 58;
  const sloganTextFontSize = 25;

  const root = parse(svgString);
  const businessTextElement = root.querySelector(
    'text[class="business-name"] tspan'
  );
  const sloganTextElement = root.querySelector('text[class="tagline"] tspan');
  const businessName = businessTextElement ? businessTextElement.text : "";
  const sloganName = sloganTextElement ? sloganTextElement.text : "";

  const businessTextElementAttributes = root.querySelector(
    'text[class="business-name"]'
  );
  const sloganTextElementAttributes = root.querySelector(
    'text[class="tagline"]'
  );

  const businessFillColor = businessTextElementAttributes
    ? businessTextElementAttributes.getAttribute("fill")
    : "";
  const sloganFillColor = sloganTextElementAttributes
    ? sloganTextElementAttributes.getAttribute("fill")
    : "";
  const businessFontFamily = businessTextElementAttributes
    ? businessTextElementAttributes.getAttribute("font-family")
    : "";
  const sloganFontFamily = sloganTextElementAttributes
    ? sloganTextElementAttributes.getAttribute("font-family")
    : "";

  const businessTextWidth = measureTextWidth(
    businessName,
    businessTextFontSize,
    businessFontFamily
  );
  const sloganTextWidth = measureTextWidth(
    sloganName,
    sloganTextFontSize,
    sloganFontFamily
  );

  return {
    businessName,
    sloganName,
    businessFillColor,
    sloganFillColor,
    businessFontFamily,
    sloganFontFamily,
    businessTextWidth,
    sloganTextWidth,
  };
};

export function svgToString(svgElement) {
  var serializer = new XMLSerializer();
  var svgString = serializer.serializeToString(svgElement);
  return svgString;
}

export const trimSvg = (svgString) => {
  // Additional width to adjust logo width after trimming
  const adjustmentWidth = 15;

  // Create a temporary container for the SVG
  const tempContainer = document.createElement("div");
  tempContainer.style.visibility = "hidden";
  tempContainer.style.position = "absolute";
  tempContainer.style.top = "-9999px";
  document.body.appendChild(tempContainer);

  // Insert the SVG into the container
  tempContainer.innerHTML = svgString;
  const svgElement = tempContainer.querySelector("svg");

  // Get the bounding box of the SVG content
  const bbox = svgElement.getBBox();

  // Update the viewBox and dimensions of the SVG
  svgElement.setAttribute(
    "viewBox",
    `${bbox.x} ${bbox.y} ${bbox.width + adjustmentWidth} ${bbox.height}`
  );
  svgElement.setAttribute("width", bbox.width + adjustmentWidth);
  svgElement.setAttribute("height", bbox.height);

  // Serialize the updated SVG back to a string
  const trimmedSvgString = new XMLSerializer().serializeToString(svgElement);

  // Clean up by removing the temporary container
  document.body.removeChild(tempContainer);

  return trimmedSvgString;
};

export const downloadLogo = (concept) => {
  try {
    if (!concept) {
      throw new Error("No logo content provided");
    }

    // Parse and clean SVG
    const svgElement = new DOMParser().parseFromString(
      concept,
      "image/svg+xml"
    ).documentElement;

    // Check for parsing errors
    const parserError = svgElement.querySelector("parsererror");
    if (parserError) {
      throw new Error("Invalid SVG content");
    }

    // Convert to string and create blob
    const svgString = svgToString(svgElement);
    const blob = new Blob([svgString], {
      type: "image/svg+xml;charset=utf-8",
    });

    // Create and trigger download
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");

    try {
      a.href = url;
      a.download = "logo.svg";
      document.body.appendChild(a);
      a.click();
    } finally {
      // Cleanup
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  } catch (error) {
    console.error("Error downloading logo:", error);
    throw error; // Re-throw to allow handling by the component
  }
};
