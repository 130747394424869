import React from "react";

const ProgressBarComponent = ({
  history,
  inspirations,
  isGeneratingLogos,
  isConceptSelection,
  isColorSelection,
  onGenerate,
  state,
  palettes,
  isGeneratingConcepts,
}) => {
  let logosSelected = () => {
    let count = 0;
    if (inspirations) {
      inspirations.forEach((i) => {
        if (i.isSelected === true) {
          count++;
        }
      });
    }
    return count;
  };

  let palettesSelected = () => {
    let count = 0;
    if (palettes) {
      palettes.forEach((p) => {
        if (p.isSelected === true) {
          count++;
        }
      });
    }
    return count;
  };

  return (
    <div className="progress">
      <div className="container">
        <div className={isGeneratingLogos ? "no-show" : ""}>
          <button
            className={
              logosSelected() >= 1
                ? "logo-generator-btn"
                : "logo-generator-btn btn-disabled"
            }
            onClick={(e) => {
              e.preventDefault();
              history.push("/color-palette");
            }}
          >
            Continue
          </button>
        </div>

        <div className={isGeneratingConcepts ? "no-show" : ""}>
          <div className={isGeneratingLogos ? "" : "no-show"}>
            <button
              className={
                palettesSelected() >= 1
                  ? "logo-generator-btn generate-logos"
                  : "logo-generator-btn generate-logos btn-disabled"
              }
              onClick={(e) => {
                e.preventDefault();
                onGenerate(state);
                history.push("/logos");
              }}
            >
              Generate Logos
            </button>
          </div>
        </div>

        {/* <div className={isGeneratingConcepts ? '' : 'no-show'} style={{
          textAlign: 'center'
        }}>
          <button
            className={isFetching ? 'logo-generator-btn generate-more-logos loading' : 'logo-generator-btn generate-more-logos'}
            onClick={e => {
              e.preventDefault();
              generateMoreConcepts(state);
            }}>
            Generate More Logos
          </button>
        </div> */}

        <div>
          <button
            className={
              isGeneratingLogos || isConceptSelection
                ? "back-btn generate-logos"
                : "back-btn"
            }
            onClick={(e) => {
              e.preventDefault();
              if (isColorSelection) {
                history.push("/inspiration");
              } else if (isGeneratingLogos) {
                history.push("/color-palette");
              } else {
                history.push("/tagline");
              }
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProgressBarComponent;
