import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import mockupImage1 from "../../assets/images/logo-mockup/finalTemplate01.png";
import mockupImage2 from "../../assets/images/logo-mockup/finalTemplate02.png";
import mockupImage3 from "../../assets/images/logo-mockup/finalTemplate03.png";
import mockupImage4 from "../../assets/images/logo-mockup/finalTemplate04.png";
import mockupImage5 from "../../assets/images/logo-mockup/finalTemplate05.png";
import mockupImage6 from "../../assets/images/logo-mockup/finalTemplate06.png";
import mockupImage7 from "../../assets/images/logo-mockup/finalTemplate07.png";
import mockupImage8 from "../../assets/images/logo-mockup/finalTemplate08.png";
import "./logo-mockup.scss";
import { downloadLogo } from "../../utils/common.util";

function LogoMockup({ logoConcepts, pickedLogo, history }) {
  const [selectedLogo, setSelectedLogo] = useState(pickedLogo);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    if (pickedLogo) {
      const pickedLogoId = logoConcepts.findIndex(
        (item) => item === pickedLogo
      );
      setSelectedId(`logo-${pickedLogoId}`);
    } else {
      history.push("/");
    }
  }, [pickedLogo, logoConcepts, history]);

  const handleItemClick = (svgLogo, id) => {
    setSelectedLogo(svgLogo);
    setSelectedId(id);
  };

  const handleDownload = () => {
    if (!selectedLogo) return;
    downloadLogo(selectedLogo);
  };

  return (
    <div className="logoBuilderSlide">
      <h1 className="logoBuilderSlideHeader">How it looks</h1>
      <div className="logoBuilderSlideContainer">
        <div className="svgItemContainer">
          {logoConcepts.map((item, index) => {
            const itemId = `logo-${index}`;
            return (
              <div
                key={uuidv4()}
                className={`logoBuilderSlideSidebarItemContainer ${
                  selectedId === itemId ? "active" : ""
                }`}
                onClick={() => handleItemClick(item, itemId)}
                dangerouslySetInnerHTML={{ __html: item }}
              />
            );
          })}
        </div>

        <div className="logoBuilderSlideTemplateViewContainer">
          <div className="logoBuilderSlideTemplateViewItem1">
            <img
              src={mockupImage1}
              alt="mockupImage1"
              className="mockupImage1"
            />
            <div className={`${selectedLogo ? "tshirt" : ""}`}>
              <div
                className="logoBuilderSlideSidebarItem"
                dangerouslySetInnerHTML={{ __html: selectedLogo }}
              />
            </div>
          </div>

          <div className="logoBuilderSlideTemplateViewItem2">
            <img src={mockupImage2} alt="mockupImage2" />
            <div className={`${selectedLogo ? "desktop" : ""}`}>
              <div
                className="logoBuilderSlideSidebarItem"
                dangerouslySetInnerHTML={{ __html: selectedLogo }}
              />
            </div>
          </div>
          <div className="logoBuilderSlideTemplateViewItem3">
            <img src={mockupImage3} alt="mockupImage3" />
            <div className={`${selectedLogo ? "mog" : ""}`}>
              <div
                className="logoBuilderSlideSidebarItem"
                dangerouslySetInnerHTML={{ __html: selectedLogo }}
              />
            </div>
          </div>

          <div className="logoBuilderSlideTemplateViewItem4">
            <img src={mockupImage4} alt="mockupImage4" />
            <div className={`${selectedLogo ? "bottle " : ""}`}>
              <div
                className="logoBuilderSlideSidebarItem"
                dangerouslySetInnerHTML={{ __html: selectedLogo }}
              />
            </div>
          </div>

          <div className="logoBuilderSlideTemplateViewItem5">
            <img src={mockupImage5} alt="mockupImage5" />
            <div className={`${selectedLogo ? "laptop " : ""}`}>
              <div
                className="logoBuilderSlideSidebarItem"
                dangerouslySetInnerHTML={{ __html: selectedLogo }}
              />
            </div>
          </div>

          <div className="logoBuilderSlideTemplateViewItem6">
            <div className={`${selectedLogo ? "notebook2" : ""}`}>
              <div
                className="logoBuilderSlideSidebarItem"
                dangerouslySetInnerHTML={{ __html: selectedLogo }}
              />
            </div>
            <img src={mockupImage6} alt="mockupImage6" />
            <div className={`${selectedLogo ? "notebook1 " : ""}`}>
              <div
                className="logoBuilderSlideSidebarItem"
                dangerouslySetInnerHTML={{ __html: selectedLogo }}
              />
            </div>
          </div>
          <div className="logoBuilderSlideTemplateViewItem7">
            <div className={`${selectedLogo ? "bag2" : ""}`}>
              <div
                className="logoBuilderSlideSidebarItem"
                dangerouslySetInnerHTML={{ __html: selectedLogo }}
              />
            </div>
            <img src={mockupImage7} alt="mockupImage7" />
            <div className={`${selectedLogo ? "bag1" : ""}`}>
              <div
                className="logoBuilderSlideSidebarItem"
                dangerouslySetInnerHTML={{ __html: selectedLogo }}
              />
            </div>
          </div>
          <div className="logoBuilderSlideTemplateViewItem8">
            <img src={mockupImage8} alt="mockupImage8" />
            <div className={`${selectedLogo ? "paper " : ""}`}>
              <div
                className="logoBuilderSlideSidebarItem"
                dangerouslySetInnerHTML={{ __html: selectedLogo }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="logoBuilderSlideFooter">
        <button
          onClick={() => {
            history.push("/logos");
          }}
          className="logoBuilderSlideFooterButton"
        >
          Back
        </button>
        <button
          className="logoBuilderSlideFooterButton"
          onClick={handleDownload}
          disabled={!selectedLogo}
        >
          Download
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  concepts: state.concepts,
  logoConcepts: state.logoConcepts,
});

export default connect(mapStateToProps)(withRouter(LogoMockup));
