import Inspirations from './inspiration';
import Information from './information';
import Colors from './color';
import Logo from './logo';

const generateLogos = (inspirationsInput, companyName, tagline, palettesInput) => {
  const inspirations = new Inspirations(inspirationsInput);
  const rules = inspirations.getInspirations();
  const information = new Information(companyName, tagline).getInformation();
  const colors = new Colors(palettesInput);
  const palettes = colors.applyRules();
  const characteristics = [];
  const logos = [];

  let count = 0;

  palettes.forEach((palette) => {
    if (rules[count] === undefined) {
      count = 0;
    }
    characteristics.push([information.name, information.tagline,
    rules[count], palette[0], palette[1], []]);
    count += 1;
  });

  for (let i = 0; i < characteristics.length; i++) {
    logos.push(new Logo(characteristics[i][0], characteristics[i][1],
      characteristics[i][2], characteristics[i][3],
      characteristics[i][4], characteristics[i][5], []).generate());
  }

  return logos;
};

export default generateLogos;