import React from 'react';

const setInnerHTML = (concept) => {
  return { __html: concept }
}

const LogoComponent = ({ concept, selectLogo, history, onDownload }) => {
  return (
    <div className="logo col-md-3" style={{ paddingLeft: '0' }}>
      <div className="concept" dangerouslySetInnerHTML={setInnerHTML(concept)}></div>
      <div className="get-logo-files">
        <button
          className="logo-generator-btn"
          style={{
            height: '40px',
            width: '154px',
            background: '#F5530C',
            marginTop: '10px',
            padding: '0'
          }}
          onClick={(e) => {
            selectLogo(concept)
            // onDownload(concept)
            // history.push('/download');
            history.push('/logo-mockup');
          }}>
          Get Logo Files
        </button>
      </div>
    </div>
  )
};
export default LogoComponent;