import React from "react";
import ReactDOM from "react-dom/client";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { routerMiddleware } from "react-router-redux";
import { createBrowserHistory } from "history";
import { composeWithDevTools } from "@redux-devtools/extension";
import App from "./App";
import logoReducer from "./lib/redux/reducer";

const documentRoot =
  document.getElementById("logo-builder") || document.getElementById("root");
const root = ReactDOM.createRoot(documentRoot);

const history = createBrowserHistory();
const middleware = routerMiddleware(history);

const store = createStore(
  logoReducer,
  composeWithDevTools(applyMiddleware(middleware))
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
