class Logo {
  constructor(companyName, tagline, rules, companyNameColor, taglineColor, icons) {
    this.companyName = companyName || 'Logo Builder';
    this.tagline = tagline || '';
    this.companyNameColor = companyNameColor;
    this.taglineColor = taglineColor;
    this.icons = icons;
    this.rules = rules;
  }

  generate() {
    const svgNS = "http://www.w3.org/2000/svg";
    const svg = document.createElementNS(svgNS, "svg");
    svg.setAttribute("xmlns", svgNS);
    svg.setAttribute("version", "1.1");
    svg.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
    svg.setAttribute("xmlns:svgjs", "http://svgjs.com/svgjs");
    svg.setAttribute("width", "300");
    svg.setAttribute("height", "230");
    svg.setAttribute("viewBox", "0 0 297 210");
    svg.setAttribute("id", "logo");

    const defs = document.createElementNS(svgNS, "defs");
    svg.appendChild(defs);

    const group = document.createElementNS(svgNS, "g");
    group.setAttribute("transform", "translate(0, 115)");
    svg.appendChild(group);

    this.drawCompanyName(group, svgNS);
    if (this.rules.tagline) {
      this.drawTagline(group, svgNS);
    }
    if (this.rules.accent) {
      this.drawAccent(group, svgNS);
    }

    return svg.outerHTML;
  }

  drawCompanyName(group, svgNS) {
    this.companyName = this.setTextCasing(this.companyName, this.rules.name.casing);

    const companyNameElement = document.createElementNS(svgNS, "text");
    companyNameElement.setAttribute("class", "business-name");
    companyNameElement.setAttribute("fill", this.companyNameColor);
    companyNameElement.setAttribute("font-family", this.rules.name.fontFamily);
    companyNameElement.setAttribute("letter-spacing", this.rules.name.letterSpacing);
    companyNameElement.setAttribute("font-size", this.rules.name.fontSize);
    companyNameElement.setAttribute("text-anchor", "middle");
    // companyNameElement.setAttribute("x", "150");
    // companyNameElement.setAttribute("y", "0");

    const tspan = document.createElementNS(svgNS, "tspan");
    tspan.textContent = this.companyName;
    companyNameElement.appendChild(tspan);

    group.appendChild(companyNameElement);

    const nameBBox = companyNameElement.getBBox();
    const nameX = 150 - (nameBBox.width / 2);

    companyNameElement.setAttribute("x", nameX);
    companyNameElement.setAttribute("y", nameBBox.height / 2);
  }

  drawTagline(group, svgNS) {
    this.tagline = this.setTextCasing(this.tagline, this.rules.tagline.casing);

    const taglineElement = document.createElementNS(svgNS, "text");
    taglineElement.setAttribute("class", "tagline");
    taglineElement.setAttribute("fill", this.taglineColor);
    taglineElement.setAttribute("font-family", this.rules.tagline.fontFamily);
    taglineElement.setAttribute("font-weight", this.rules.tagline.fontWeight);
    taglineElement.setAttribute("letter-spacing", this.rules.tagline.letterSpacing);
    taglineElement.setAttribute("font-size", this.rules.tagline.fontSize);
    taglineElement.setAttribute("text-anchor", "middle");
    // taglineElement.setAttribute("x", "150");
    // taglineElement.setAttribute("y", "30");

    const tspan = document.createElementNS(svgNS, "tspan");
    tspan.textContent = this.tagline;
    taglineElement.appendChild(tspan);

    group.appendChild(taglineElement);

    const taglineBBox = taglineElement.getBBox();
    const companyNameBBox = group.firstChild.getBBox();

    let taglineX, taglineY;

    switch (this.rules.tagline.taglinePlacement) {
      case 'middle':
        taglineX = 150 - (taglineBBox.width / 2);
        taglineY = companyNameBBox.y + companyNameBBox.height + 10;
        break;
      case 'left':
        taglineX = companyNameBBox.x;
        taglineY = companyNameBBox.y + companyNameBBox.height + 10;
        break;
      case 'right':
        taglineX = companyNameBBox.x + companyNameBBox.width - taglineBBox.width;
        taglineY = companyNameBBox.y + companyNameBBox.height + 10;
        break;
      case 'belowAccent':
        taglineX = 150 - (taglineBBox.width / 2);
        taglineY = companyNameBBox.y + companyNameBBox.height + 20;
        break;
      default:
        taglineX = 150;
        taglineY = 115;
        break;
    }

    taglineElement.setAttribute("x", taglineX);
    taglineElement.setAttribute("y", taglineY);
  }

  drawAccent(group, svgNS) {
    const taglineBBox = group.childNodes[1].getBBox();

    switch (this.rules.accent.accentPlacement) {
      case 'linesBothSidesOfTagline': {
        const lineWidth = this.rules.accent.accentWidth || 15;
        const line1 = document.createElementNS(svgNS, "line");
        const line2 = document.createElementNS(svgNS, "line");

        line1.setAttribute("x1", taglineBBox.x - lineWidth - 10);
        line1.setAttribute("y1", taglineBBox.y + taglineBBox.height / 2);
        line1.setAttribute("x2", taglineBBox.x - 10);
        line1.setAttribute("y2", taglineBBox.y + taglineBBox.height / 2);
        line1.setAttribute("stroke", this.companyNameColor);

        line2.setAttribute("x1", taglineBBox.x + taglineBBox.width + 10);
        line2.setAttribute("y1", taglineBBox.y + taglineBBox.height / 2);
        line2.setAttribute("x2", taglineBBox.x + taglineBBox.width + lineWidth + 10);
        line2.setAttribute("y2", taglineBBox.y + taglineBBox.height / 2);
        line2.setAttribute("stroke", this.companyNameColor);

        group.appendChild(line1);
        group.appendChild(line2);
        break;
      }
      case 'lineBetweenNameAndTagline': {
        const companyNameBBox = group.firstChild.getBBox();
        const line = document.createElementNS(svgNS, "line");

        line.setAttribute("x1", companyNameBBox.x);
        line.setAttribute("y1", companyNameBBox.y + companyNameBBox.height + 5);
        line.setAttribute("x2", companyNameBBox.x + companyNameBBox.width);
        line.setAttribute("y2", companyNameBBox.y + companyNameBBox.height + 5);
        line.setAttribute("stroke", this.companyNameColor);

        group.appendChild(line);
        break;
      }
      default:
        break;
    }
  }

  setTextCasing(text, casing) {
    switch (casing) {
      case 'lowercase':
        return text.toLowerCase();
      case 'uppercase':
        return text.toUpperCase();
      case 'pascalcase':
        return text.replace(/\w+/g, (w) => w[0].toUpperCase() + w.slice(1).toLowerCase());
      default:
        return text;
    }
  }
}

export default Logo;
