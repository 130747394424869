import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { v4 as uuid } from "uuid";

import LogoComponent from "../../components/LogoComponent";
import Navbar from "../../components/common/Navbar";
import ProgressBar from "../../components/common/ProgressBarComponent";
import { selectLogo, setLogoConceptsActionCr } from "../../lib/redux/actions";
import LoadingComponent from "../../components/common/LoadingComponent";
import aData from "../../data/text-logo-data/a.json";
import bData from "../../data/text-logo-data/b.json";
import cData from "../../data/text-logo-data/c.json";
import dData from "../../data/text-logo-data/d.json";
import eData from "../../data/text-logo-data/e.json";
import fData from "../../data/text-logo-data/f.json";
import gData from "../../data/text-logo-data/g.json";
import hData from "../../data/text-logo-data/h.json";
import iData from "../../data/text-logo-data/i.json";
import jData from "../../data/text-logo-data/j.json";
import kData from "../../data/text-logo-data/k.json";
import lData from "../../data/text-logo-data/l.json";
import mData from "../../data/text-logo-data/m.json";
import nData from "../../data/text-logo-data/n.json";
import oData from "../../data/text-logo-data/o.json";
import pData from "../../data/text-logo-data/p.json";
import qData from "../../data/text-logo-data/q.json";
import rData from "../../data/text-logo-data/r.json";
import sData from "../../data/text-logo-data/s.json";
import tData from "../../data/text-logo-data/t.json";
import uData from "../../data/text-logo-data/u.json";
import vData from "../../data/text-logo-data/v.json";
import wData from "../../data/text-logo-data/w.json";
import xData from "../../data/text-logo-data/x.json";
import yData from "../../data/text-logo-data/y.json";
import zData from "../../data/text-logo-data/z.json";
import { downloadLogo, getLogoWithTextBasedIcon, parseSvg, trimSvg, updateSvgAttributes } from "../../utils/common.util";

const jsonFiles = {
  a: aData,
  b: bData,
  c: cData,
  d: dData,
  e: eData,
  f: fData,
  g: gData,
  h: hData,
  i: iData,
  j: jData,
  k: kData,
  l: lData,
  m: mData,
  n: nData,
  o: oData,
  p: pData,
  q: qData,
  r: rData,
  s: sData,
  t: tData,
  u: uData,
  v: vData,
  w: wData,
  x: xData,
  y: yData,
  z: zData,
};

const LogoList = withRouter(
  ({
    concepts,
    isFetching,
    isGeneratingMoreLogos,
    state,
    selectLogo,
    setLogoConceptsDispatcher,
    history,
  }) => {
    // console.log({ selectedInspirations });
    const [svgData, setSvgData] = useState(null);
    const [generatedLogoConcepts, setGeneratedLogoConcepts] = useState([]);

    const logoCards = [];
    const firstChar = state.companyName[0].toLowerCase();

    concepts = concepts || [];

    useEffect(() => {
      if (concepts.length > 0 && svgData) {
        // Generated logo concepts based on the textual icons length
        const updatedGeneratedLogoConcepts = concepts
          .slice(0, svgData.icons.length)
          .map((svgLogo, idx) => {
            console.log({logo: svgLogo})
            const {
              businessName,
              sloganName,
              businessFillColor,
              sloganFillColor,
              businessFontFamily,
              sloganFontFamily,
              businessTextWidth,
              sloganTextWidth,
            } = parseSvg(svgLogo);

            const updatedWidth = Math.max(businessTextWidth, sloganTextWidth);

            // Update icon's attributes
            const textualSVGIconObj = svgData.icons[idx];
            const svgAttributes = {
              height: "100",
              width: "100",
              fill: businessFillColor,
            };
            const textualSVGIcon = updateSvgAttributes(
              textualSVGIconObj.contents,
              svgAttributes
            );

            const outerSvgAttrs = {
              width: updatedWidth,
              viewBox: `0 0 ${updatedWidth} 100`,
            };

            // Combine Icon + Generated Logo
            let combinedFinalLogo = getLogoWithTextBasedIcon(
              textualSVGIcon,
              businessName,
              sloganName,
              businessFillColor,
              sloganFillColor,
              businessFontFamily,
              sloganFontFamily,
              outerSvgAttrs
            );

            combinedFinalLogo = trimSvg(combinedFinalLogo);

            return combinedFinalLogo;
          });

        setGeneratedLogoConcepts(updatedGeneratedLogoConcepts);
        setLogoConceptsDispatcher(updatedGeneratedLogoConcepts);
      }
    }, [
      concepts,
      svgData,
      setGeneratedLogoConcepts,
      setLogoConceptsDispatcher,
    ]);

    useEffect(() => {
      const loadJson = () => {
        const jsonData = jsonFiles[firstChar];
        if (jsonData) {
          setSvgData(jsonData);
        } else {
          console.error("JSON file not found for character:", firstChar);
        }
      };

      loadJson();
    }, [firstChar]);

    generatedLogoConcepts.forEach((concept) => {
      logoCards.push(
        <div key={uuid()} style={{ position: "relative" }}>
          <LogoComponent
            concept={concept}
            selectLogo={selectLogo}
            history={history}
            onDownload={downloadLogo}
          />
        </div>
      );
    });

    if (!svgData) {
      return <div>Loading SVG data from json files...</div>;
    }

    return (
      <div>
        <LoadingComponent
          isFetching={isFetching}
          isGeneratingMoreLogos={isGeneratingMoreLogos}
        />
        <Navbar />
        <div className="logo-generator-base inspiration">
          <div className="container" style={{ paddingBottom: "70px" }}>
            <div
              className="logo-inspiration-container"
              style={{ paddingTop: "40px" }}
            >
              {logoCards}
            </div>
          </div>
        </div>
        <ProgressBar
          history={history}
          isGeneratingLogos={true}
          isGeneratingConcepts={true}
          state={state}
          isFetching={isFetching}
        />
      </div>
    );
  }
);

const mapStateToProps = (state) => ({
  isFetching: state.isFetching,
  isGeneratingMoreLogos: state.isGeneratingMoreLogos,
  concepts: state.concepts,
  selectedInspirations: state.inspirations?.filter((item) => item.isSelected),
  state,
});

const mapDispatchToProps = (dispatch) => ({
  selectLogo: (logo) => {
    dispatch(selectLogo(logo));
  },
  setLogoConceptsDispatcher: (enhancedConcepts) => {
    dispatch(setLogoConceptsActionCr(enhancedConcepts));
  },
});

const LogoListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LogoList);

export default LogoListContainer;
