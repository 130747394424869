import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setTaglineText } from "../lib/redux/actions";
import Navbar from "./common/Navbar";

class TaglineComponent extends React.Component {
  state = {
    tagline: this.props.tagline || "",
  };

  handleInputChange = (event) => {
    this.setState({ tagline: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.setTaglineText(this.state.tagline);
    this.props.history.push("/inspiration");
  };

  render() {
    return (
      <>
        <Navbar />
        <div className="logo-generator-base">
          <div className="container">
            <div className="logo-generator-form">
              <span className="orange-bar"></span>
              <form onSubmit={this.handleSubmit}>
                <label htmlFor="tagline">Tagline or Slogan (Optional)</label>
                <input
                  id="tagline"
                  onChange={this.handleInputChange}
                  value={this.state.tagline}
                  ref={(input) => input && input.focus()}
                  autoFocus
                />

                <div style={{ marginTop: "30px" }}>
                  <button type="submit" className="logo-generator-btn">
                    Continue
                  </button>

                  <button
                    className="back-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push("/");
                    }}
                  >
                    Back
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  tagline: state.tagline,
});

const mapDispatchToProps = {
  setTaglineText,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TaglineComponent));
