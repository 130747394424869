import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setCompanyName } from "../../lib/redux/actions";
import "./company-name.scss";

class CompanyName extends React.Component {
  state = { companyName: this.props.companyName || "" };

  handleInputChange = (event) => {
    this.setState({
      companyName: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.setCompanyName(this.state.companyName);
    this.props.history.push("/tagline");
  };

  componentDidUpdate(prevProps) {
    if (prevProps.companyName !== this.props.companyName) {
      this.setState({ companyName: this.props.companyName });
    }
  }

  render() {
    return (
      <div className="logo-generator-form">
        <span className="orange-bar"></span>
        <form onSubmit={this.handleSubmit}>
          <label htmlFor="company_name">Business or Organization Name</label>
          <input
            id="company_name"
            type="text"
            value={this.state.companyName}
            onChange={this.handleInputChange}
            autoFocus
          />
          <span className="bottom-label">
            This will be used as your main logo text.
          </span>
          <button
            type="submit"
            className={
              this.state.companyName.length > 0
                ? "logo-generator-btn"
                : "logo-generator-btn btn-disabled"
            }
            disabled={this.state.companyName.length === 0}
          >
            Continue
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companyName: state.companyName,
});

const mapDispatchToProps = {
  setCompanyName,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CompanyName));
