import AppRoutes from "./AppRoutes";
import "./assets/sass/app.scss";

function App() {
  return (
    <>
      <AppRoutes />
    </>
  );
}

export default App;
